import React, { useEffect, useState, setState } from "react"
import { Router } from "@reach/router"
import { withPrefix } from 'gatsby'
import FirebaseApp from "../../config/firebase"
import "./index.css";
import Logo from "./../../images/icon-01.png"
let database = FirebaseApp.database().ref("/")

const NotFound = () => <p>Sorry, nothing here</p>
const UserId = props => {
  const [name, setName] = useState("")
  const [father, fatherName] = useState("")
  const [course, courseCompleted] = useState("")
  const [passed, PassedCourseDate] = useState("")

  useEffect(() => {
    (async () => {
      database
        .child("PassesStudents/" + props.title + "/")
        .once("value", async res => {
          var data = res.val()
          // data = res.key
          setName(data.name)
          fatherName(data.fatherName)
          courseCompleted(data.course)
          PassedCourseDate(data.PassedCourseDate)

          console.log("============certifiacte id==========>", data)
        })
    })()
  }, [])

  return (
    <div className="_main_container2">
       <img
                src={Logo}
                className="_LogoQr"
              />
      <h3 style={{ textAlign: "center", marginTop: 10}}>Certificate of Completion</h3>
      <p style={{ textAlign: "center"}}>
        This is to certify that <b>{name}</b> s/o <b> {father} </b>
        has successfully completed the <b> {course} </b> course on{" "}
        <b> {passed} </b> at <b> iHunar Academy Mithi</b>
      </p>
    </div>
  )
}

const CertificateID = ({location }) => {
  console.log(location)
  return (
      <Router basepath={withPrefix("/CertificateID")}>
         <NotFound default />
      <UserId path="/:title" />
    </Router>
  )
}

export default CertificateID
